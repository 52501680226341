import React, { useState, useEffect, useRef } from "react";
import '../App.css';
import { motion, transform } from 'framer-motion'
import { AnimatePresence } from "framer-motion";
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';


  
const Control = ({ projectCount, categories, search, setSearch, searchInput, filteropen, filter, toggle2, expand, open2, toggle, open, handleReset, handleChange, active }) => {
  

 


  

    return (
        <>







<div className="tagsMenu" >
  
{categories.map((category, index) => (
    <div
   
    >
      
   
            

        <span
          key={category}
          onClick={active === category ? handleReset : e => handleChange(category)}

          className={`filterListItem ${active === category ? 'active' : ''}`}
        >
          {category}
          {`${index === categories.length - 1 ? '' : ', '}`}
        </span>

     
    </div>
))}




</div>
       </>

    );
};

export default Control;
