import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useIntersection } from './Observer';
import { useSpring, animated } from "react-spring";




function Image2 ({ src, style, caption }) {

  
  const imageRef = useRef(null);
  const [imageStyle, setImageStyle] = useState(null);
  const [overlayStyle, setOverlayStyle] = useState({});

  const handleOpen = () => {
    document.body.style.overflow = 'hidden';
    const {
      top,
      left,
      width,
      height,
    } = imageRef.current.getBoundingClientRect();
    const { naturalWidth, naturalHeight } = imageRef.current;

 
    

    setImageStyle({
      top,
      left,
      width,
      height,
    });
    setOverlayStyle({ opacity: 0 });
    setTimeout(() => {
      setImageStyle(null);
      document.body.style.overflow = null;
    }, 300);
  };

  const [imgDimensionX, setDimensionX] = useState()
  const [imgDimensionY, setDimensionY] = useState()



  const setImageDimension = value => {
    setDimensionY(imageRef.current.naturalHeight);
    setDimensionX(imageRef.current.naturalWidth);

  };














  const [imageLoading, setImageLoading] = useState(true);
  const [pulsing, setPulsing] = useState(true);

  const imageLoaded = useCallback(() => {
    setImageLoading(false);
    setImageDimension();
    setPulsing(false);
  }, [setImageDimension]);


  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const count = 1;





  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const shouldApplyBlur = isMobile ? false : pulsing;
 



  return (

    <>




 
      
    <div
      ref={imgRef}
  
    >

    <div
        className={`${pulsing ? "pulse" : ""} loadable`}
      >


      <img
        initial={{ opacity: 1}}

      animate={{
        opacity: pulsing ? 0.5 : 1,
      }}
      transition={
        (
        { opacity: { delay: 0.5, duration: 0.4 } })
      }
      onLoad={imageLoaded}
      
        ref={imageRef}
        src={src}
      
        className={`${style}`}
        style={{width: {imgDimensionX}, height:{imgDimensionY}}}
   
      />
           

      </div>
  
      </div>
 
    </>
  );
}

export default Image2;
