import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import sanityClient from "./client.js"
import Header from './components/Header';
import Projects from './components/Projects';
import { HashRouter as Router, Route, Routes, Link, useLocation, BrowserRouter } from 'react-router-dom';
import Search from './components/Search.js'
import Holding from './components/Holding.js';

function App() {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "project"]| order(order asc){
      title,
      slug,
      description,
      projectImage[]{
        image{
          asset->{
            _id,
            url
          },
},
          size
        },
      mainVideo[]{
        video{
          asset->{
            _id,
            url
          },
        },
        size,
        caption
      },
        "categories": categories[]->title,
    }`)
    .then((data) => {
      setData(data);
      // Extract unique categories from the fetched data
      const uniqueCategories = [...new Set(data.map((item) => item.categories).flat())];
      setCategories(uniqueCategories);
    })
    .catch(console.error);
    
}, []);

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterChip, setFilterChip] = useState("");
  const [active, setIsActive] = useState("");

  const goToTop = () => {
    // Scroll to the top of the page
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  
    // Scroll to the top of a specific div
    const targetDiv = document.querySelector('#scrollThis');
    if (targetDiv) {
      targetDiv.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  
 
  // exclude column list from filter
  const excludeColumns = ["id", "videos"];
 
  const [search, setSearch] = useState(true);
  const [card, setCard] = useState(true);

  // handle change event of search input
  const handleChange = value => {
    setSearch(value)
    setSearchText(value);
    setIsActive(value);    
    filterData(value);
    goToTop(true);

  };

  const handleChangeDate = value => {
    setSearchText(value);
    setIsActive(value);
    filterData(value);

  };
 
  const handleReset = postData => {
    setTimeout(() => {
      setIsActive();
        }, 0);
      setSearchText('');
      setFiltered(data);
      goToTop(true);

    };

    // filter data by search value
    const filterData = (value) => {
      const lowercasedValue = value.toLowerCase().trim();
      if (lowercasedValue === "") {
        // Only update state if the value is different
        if (filtered !== data) {
          setFiltered(data);
        }
      } else {
        const filteredData = data.filter(item => {
          return Object.keys(item).some(key =>
            excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
          );
        });
    
        // Only update state if the filtered data is different
        if (filtered !== filteredData) {
          setFiltered(filteredData);
        }
      }
    };

    
  

  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
     setFiltered(data);
  }, [data]);



  
const projectCount = filtered.length;




  return (
    <div className="App">
      <Holding />
        {/* <Header /> */}
        {/* <Search filtered={filtered} data={data} categories={categories}  filter={e => handleChange(e.target.value)} handleReset={handleReset}    handleChange={handleChange}  active={active}/> */}
      <BrowserRouter>
          {/* <Projects active={active} data={filtered} handleChange={handleChange}/> */}
       </BrowserRouter>
    </div>
  );
}

export default App;
