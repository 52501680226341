import React, { useState } from 'react';
import Image2 from './ImageNoLazy'
import holding1 from '../img/DSC01602.jpg'
import holding2 from '../img/DSC01738.jpg'


function Holding() {

  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  
  return (
    <div>
      <div className="bar" onClick={handleClick} >
      </div>
      {isVisible && (
        <div className='Info'>
          <p>Kristina<br></br><a href="tel:+43 676 5606058">+43 676 5606058</a><br></br>
          <a href="mailto:hey@growglowbloom.com">hey@growglowbloom.com</a><br></br>
          <a href="https://www.instagram.com/growglowbloom/">@growglowbloom</a>
</p>
        </div>
      )}
    <div className='holding'>
    <div className='child'>
          <Image2 style={"fill2"} src={holding1}></Image2>
        </div>
      {/* <div className='flex50'>
        <div className='child'>
          <Image2 style={"fill2"} src={holding1}></Image2>
        </div>
      
        <div className='child'>
          <Image2 style={"fill2"} src={holding2}></Image2>
        </div>
      </div> */}

    </div>
    <div className='hero'>
    <div className='small'>Under construction</div>
    </div>
    {/* <div className='hero'>
      <div className='wrap'>
    <p className='center'>Grow Glow Bloom</p>
    <p className=''>Nomadic studio <br></br>
for holistic wellbeing <br></br>
experiences</p>
    </div>
    </div> */}
    </div>
  )
}

export default Holding